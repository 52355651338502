import * as React from "react"
import Layout from "../components/layout"
import Event from "../components/Event"

const EventPage = () => (
  <Layout>
    <Event />
  </Layout>
)

export default EventPage